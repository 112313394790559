import React from "react"
const netlifyIdentity = typeof window !== `undefined` ? require("netlify-identity-widget") : null

class NetlifyIdentify extends React.Component {
    componentDidMount() {
        const windowGlobal = typeof window !== "undefined" && window

        windowGlobal.netlifyIdentity = netlifyIdentity
        // You must run this once before trying to interact with the widget
        netlifyIdentity.init()
    }

    render() {
        return <div className="netlifyIdentity" />
    }
}

export default NetlifyIdentify
