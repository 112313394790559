import React from "react"
import { Helmet } from "react-helmet"
import Footer from "./Footer/index"
import Navbar from "./Navbar/index"
import useSiteMetadata from "./SiteMetadata"
import { withPrefix } from "gatsby"
import NetlifyIdentify from "./NetlifyIdentity"

import "../styles/all.scss"

const TemplateWrapper = ({ children, transNav }) => {
    const { title, description } = useSiteMetadata()

    return (
        <div>
            <Helmet>
                <html lang="en" />
                <title>{title}</title>
                <meta name="description" content={description} />

                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${withPrefix("/")}img/favicon/apple-touch-icon.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix("/")}img/favicon/favicon-32x32.png`}
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix("/")}img/favicon/favicon-16x16.png`}
                    sizes="16x16"
                />
                <meta name="theme-color" content="#fff" />

                <meta property="og:type" content="business.business" />
                <meta property="og:title" content={title} />
                <meta property="og:url" content="/" />
                <meta property="og:image" content={`${withPrefix("/")}img/og-image.jpg`} />
                <script
                    src="https://cdn.jsdelivr.net/npm/intersection-observer@0.10.0/intersection-observer.js"
                    type="text/javascript"
                />
                <script
                    src="https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver"
                    type="text/javascript"
                />
            </Helmet>
            <NetlifyIdentify />
            <Navbar isTransparent={transNav} />
            <div>{children}</div>
            <Footer />
        </div>
    )
}

export default TemplateWrapper
